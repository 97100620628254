.bib-author {
    font-size: 18px;
}

.bib-share {
    color: $grey-color;
    font-size: 14px;
    text-align: right;
}
.bib-share a {
    color: inherit;
}
